import moment from 'moment';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
  tap,
} from 'rxjs';

import { CommonModule } from '@angular/common';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ApiClientInterface, ROUTER_FACADE_TOKEN } from '@do/app-common';
import { BaseDto } from '@do/common-dto';
import {
  FieldType,
  FilterOperator,
  FilterType,
  FilterValue,
} from '@do/common-interfaces';
import { camelize, getDescendantProp } from '@do/common-utils';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { IconButtonComponent } from '../../core/icon-button/icon-button.component';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { FormFieldWrapperComponent } from '../form-field-wrapper.component';

/**
 * Interfaccia che rappresenta la configurazione del campo Autocomplete.
 */
export interface AutocompleteConfig {
  entity: string;
  decodeField: string[];
  decodeFieldDisabled?: boolean;
  decodeFieldFormat?: FieldType[];
  codeField?: string;
  filters?: FilterValue[];
  join?: string[];
  tooltip?: string;
}

@Component({
  selector: 'do-autocomplete-field',
  styleUrls: ['./autocomplete-field.component.scss'],
  template: `
    <do-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError()"
      [errors]="errors()"
      [tooltip]="config.tooltip || ''"
    >
      <div class="d-flex align-items-center">
        <div
          class="position-relative code me-1"
          [hidden]="!config.codeField"
          [class.big]="bigInputCode"
        >
          <input
            #codeInput
            type="text"
            class="form-control"
            [class.is-invalid]="shouldShowError()"
            [formControl]="formControl"
            [ngbTypeahead]="searchByCode"
            [inputFormatter]="codeFormatter"
            [resultFormatter]="resultFormatter"
            [showHint]="true"
            [editable]="false"
            (selectItem)="selected($event)"
            (blur)="blur(codeInput, decodeInput)"
            [readonly]="readOnly"
            [container]="'body'"
          />
          <div
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="visually-hidden">Caricamento...</span>
          </div>
        </div>
        <div class="position-relative decode">
          <input
            #decodeInput
            type="text"
            class="form-control "
            [class.is-invalid]="shouldShowError()"
            [formControl]="formControl"
            [ngbTypeahead]="searchByDecode"
            [inputFormatter]="decodeFormatter"
            [resultFormatter]="resultFormatter"
            [showHint]="true"
            [editable]="decodeFieldDisabled()"
            (selectItem)="selected($event)"
            (blur)="blur(codeInput, decodeInput)"
            [readonly]="decodeFieldDisabled() || readOnly"
            [container]="'body'"
          />
          <div
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="visually-hidden">Caricamento...</span>
          </div>
        </div>
        <ng-container>
          <do-icon-button
            *ngIf="
              canView && !formControl.disabled && !readOnly && allowFullSearch
            "
            class="ms-1"
            cssClasses="text-primary"
            icon="search"
            [title]="'full search' | translate"
            (clicked)="search()"
          >
          </do-icon-button>
          <do-icon-button
            *ngIf="canView && formControl.value"
            class="ms-1"
            cssClasses="text-primary"
            icon="visibility"
            [title]="'view' | translate"
            (clicked)="view()"
          >
          </do-icon-button>
          <do-icon-button
            *ngIf="canAdd && !formControl.disabled && !readOnly"
            class="ms-1"
            cssClasses="text-primary"
            icon="add_circle"
            [title]="'add new' | translate"
            (clicked)="add()"
          >
          </do-icon-button>
        </ng-container>
      </div>
    </do-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormFieldWrapperComponent,
    ReactiveFormsModule,
    IconButtonComponent,
    NgbTypeahead,
    CommonModule,
    TranslateModule,
  ],
})
export class AutocompleteFieldComponent
  extends BaseFormFieldComponent
  implements ControlValueAccessor, OnInit
{
  routerFacade = inject(ROUTER_FACADE_TOKEN);

  @Input()
  placeholder = '';

  @Input()
  config!: AutocompleteConfig;

  // @Input()
  // bindValue = 'id';

  @Input()
  canAdd? = false;

  @Input()
  canView? = false;

  @Input()
  allowFullSearch? = true;

  @Input()
  exactMatch = false;

  @Input()
  startSearchAfterInsertedChars = 2;
  isLoading = false;

  @Input()
  bigInputCode = false;

  @Output()
  selectedItem = new EventEmitter<any>();
  // decodeFormControl = new FormControl();

  get apiClient() {
    const entityName = camelize(this.config.entity);
    return this.injector.get(
      entityName + 'ApiClient'
    ) as ApiClientInterface<BaseDto>;
  }

  decodeFieldDisabled(): boolean {
    if (this.config.decodeFieldDisabled === true) {
      return true;
    }
    return false;
  }

  // TODO: - quali FieldType consideriamo?
  getFormattedValue(value?: any, type?: FieldType): any {
    switch (type) {
      case FieldType.date:
        return value != null ? moment(value).format('DD/MM/YYYY') : '';
      case FieldType.time:
        return value != null ? moment(value).format('HH:mm:ss') : '';
      case FieldType.datetime:
        return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '';
    }
    return value;
  }

  // moment(arg0: any) {
  //   throw new Error('Method not implemented.');
  // }

  codeFormatter = (x: any) => {
    if (this.config.codeField)
      return getDescendantProp(x, this.config.codeField);
  };

  decodeFormatter = (x: any) => {
    let value = '';
    this.config.decodeField.map((dec, index) => {
      let type: FieldType = FieldType.text;
      if (this.config.decodeFieldFormat != undefined) {
        type = this.config.decodeFieldFormat[index];
      }
      value +=
        (value == '' ? '' : ' - ') +
        this.getFormattedValue(getDescendantProp(x, dec), type);
    });
    return value;
  };

  resultFormatter = (x: any) => {
    let result = '';

    this.config.decodeField.map((dec, index) => {
      let type: FieldType = FieldType.text;
      if (this.config.decodeFieldFormat != undefined) {
        type = this.config.decodeFieldFormat[index];
      }
      result +=
        (result == '' ? '' : ' - ') +
        this.getFormattedValue(getDescendantProp(x, dec), type);
    });

    if (this.config.codeField) {
      return `${x[this.config.codeField]} - ${result}`;
    } else {
      return `${result}`;
    }
  };

  blur(codeInput: HTMLInputElement, decodeInput: HTMLInputElement) {
    if (!this.formControl.value) {
      codeInput.value = decodeInput.value = '';
    }
  }

  selected($event: NgbTypeaheadSelectItemEvent) {
    this.formControl.setValue($event.item);

    this.selectedItem.emit($event.item);
  }

  searchByCode: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) => {
    if (!this.config.codeField) return of([]);

    const codeField = this.config.codeField;
    return text$.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      filter((term) => term.length >= this.startSearchAfterInsertedChars),
      tap(() => {
        this.isLoading = true;
        this.cd.detectChanges();
      }),
      switchMap((term) => {
        // const join: string[] = [];
        const allFilters: FilterValue[] = [
          {
            field: codeField,
            operator: this.exactMatch
              ? FilterOperator.equals
              : FilterOperator.startsWith,
            value: [term],
            type: FilterType.text,
          },
        ];
        if (this.config.filters) {
          this.config.filters.map((f) => {
            // if (f.field.indexOf('.') > 0) {
            //   const splitted = f.field.split('.');
            //   if (splitted.length > 1) {
            //     join.push(splitted[0]);
            //   }
            // }
            allFilters.push(f);
          });
        }
        return this.apiClient
          .getPaged(
            10,
            0,
            {
              [codeField]: 'asc',
            },
            allFilters,
            // join
            this.config.join
          )
          .pipe(
            map((res) => res.items),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.isLoading = false;
              this.cd.markForCheck();
            })
          );
      })
    );
  };

  searchByDecode: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      filter((term) => {
        return term.length >= this.startSearchAfterInsertedChars;
      }),
      tap(() => {
        this.isLoading = true;
        this.cd.detectChanges();
      }),
      switchMap((term) => {
        let sortBy: any = {};
        // const join: string[] = [];
        // sort multiplo
        // filtro su più campi con OR
        const allFilters: FilterValue[] = this.config.decodeField.map(
          (f, i) => {
            sortBy = Object.assign(sortBy, {
              [f]: 'asc',
            });
            return {
              field: f,
              operator: this.exactMatch
                ? FilterOperator.equals
                : FilterOperator.contains,
              value: [term],
              type: FilterType.text,
              bracketCode: 'multi_' + i,
            };
          }
        );

        // considero solo il primo campo
        // const allFilters: FilterValue[] = [
        //   {
        //     field: this.config.decodeField[0],
        //     operator: FilterOperator.contains,
        //     value: [term],
        //     type: FilterType.text,
        //   },
        // ];
        // sortBy = Object.assign(sortBy, {
        //    [this.config.decodeField[0]]: 'asc',
        // })

        if (this.config.filters) {
          this.config.filters.map((f) => {
            // if (f.field.indexOf('.') > 0) {
            //   const splitted = f.field.split('.');
            //   if (splitted.length > 1) {
            //     join.push(splitted[0]);
            //   }
            // }
            allFilters.push(f);
          });
        }
        return this.apiClient
          .getPaged(
            10,
            0,
            sortBy,
            allFilters,
            // join,
            this.config.join
          )
          .pipe(
            map((res) => res.items),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.isLoading = false;
              this.cd.markForCheck();
            })
          );
      })
    );

  async add() {
    if (this.config.entity) {
      try {
        const addedItem = await this.routerFacade.addNewEntity(
          this.config.entity
        );
        this.formControl.setValue(addedItem);
        this.cd.markForCheck();
      } catch (error) {
        /* empty */
      }
    }
  }

  // TODO: - qui devo usare anche gli eventuali filtri
  async search() {
    if (this.config.entity) {
      try {
        const foundItem = await this.routerFacade.searchEntity(
          this.config.entity,
          this.config.filters
        );
        this.formControl.setValue(foundItem);
        this.selectedItem.emit(foundItem);
        this.cd.markForCheck();
      } catch (error) {
        /* empty */
      }
    }
  }

  async view() {
    if (this.config.entity) {
      this.routerFacade.openEntityDetail(
        this.config.entity,
        this.formControl.value.id,
        false
      );
    }
  }

  constructor(private cd: ChangeDetectorRef, private injector: Injector) {
    super();
  }
}
